.user_management_title {
  font-family: 'Kepler Std';
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 2%;
}

.user_management_container {
  font-family: 'Adelle Sans';

  padding-top: 32px;
  width: 100%;
  height: 70%;
  padding-left: 80px;
  padding-right: 80px;
}

.user_management_container hr {
  height: 1px;
  background-color: lightgray;
  border: none;
}

.user_management_option {
  outline-width: 0;
  border: none;
  background: none;
  font-size: 16px;
  font-family: 'Adelle Sans';
}

a.user_management_option_label {
  text-decoration: none;
  color: #1976d2;
}

.user_management_option_label:hover {
  cursor: pointer;
}

.data_grid {
  height: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
}

.user_management_toolbar {
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}
