.logo_dcmf {
  position: absolute;
  width: 537px;
  height: 155px;
  left: 82px;
  top: 50%;
  transform: translate(0, -50%);
}

.ellipse_small {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.ellipse_big_half {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.ellipse_big_sliver {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
