.title_container {
  padding-top: 30px;
  padding-bottom: 17px;
  padding-left: 80px;
}

.title_object_overview {
  font-family: 'Kepler Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;

  color: #0c2c40;
}

@media only screen and (max-width: 4000px) {
  .overview_container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .data_containers {
    height: 100%;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2%;
  }

  .data_container {
    padding-top: 15px;
    height: 100%;
    flex: 1;
    background: #ffffff;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .scroll_container {
    width: 100%;
  }

  .nav_button_container {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    gap: 16px;
  }

  .bottom_container {
    width: 100%;
    height: 100%;
    max-height: 200px;
    padding-bottom: 5%;

    display: flex;
  }

  .flex_spacer {
    width: 100%;
    height: 100%;

    display: flex;
  }

  .user_dropdown {
    width: 100%;
    height: 100%;
    padding-left: 80px;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-self: center;

    .input_title {
      padding-bottom: 4px;
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .overview_container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .data_containers {
    flex-direction: column;
    align-items: center;
    gap: 5%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 16px;

    overflow: scroll;
  }

  .data_container {
    width: 100%;
    height: 100%;
    flex: 1;
    background: #ffffff;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: middle;
    justify-content: space-between;
    gap: 16px;
  }

  .scroll_container {
    width: 100%;
  }

  .nav_button_container {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    gap: 16px;
  }

  .bottom_container {
    width: 100%;
    height: 100%;
    max-height: 200px;
    padding-bottom: 5%;
    gap: 10px;

    flex-direction: column;
    display: flex;
  }

  .flex_spacer {
    width: 100%;
    height: 100%;

    justify-content: center;
    align-items: center;

    display: flex;
  }

  .user_dropdown {
    width: 100%;
    height: 100%;
    padding-left: 32px;
    padding-right: 32px;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-self: center;

    .input_title {
      padding-bottom: 4px;
      font-size: 16px;
    }
  }
}

.wizard_overview_progress_bar {
  position: absolute;
  width: 100%;
  height: 15px;
  bottom: 0;
  left: 0;
  background: #ff7700;
  border-radius: 0px 0px 4px 4px;
}

.check_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.check_background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.check_text {
  font-family: 'Adelle Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #0c2c40;
}

.text_holder {
  margin-bottom: 16px;
}

.check_holder {
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
