.button_container {
  width: 361px;
  height: 40px;
  margin-top: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;

  border: 1px solid #3f81ba;
  box-sizing: border-box;
  border-radius: 36px;
}

    .button_container:hover {
        cursor: pointer;
        transition: 0.2s;
        transform: scale(1.05);
    }

    .button_container:not(hover) {
        transition: 0.2s;
    }

.button_container_disabled {
  width: 361px;
  height: 40px;
  margin-top: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;

  border: 1px solid #3f81ba;
  box-sizing: border-box;
  border-radius: 36px;
  background-color: rgb(207, 207, 207);
}

.button_text {
  font-family: "Adelle Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  padding-left: 10px;

  color: #0c2c40;

  cursor: default;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.button_text_disabled:hover {
  cursor: default !important;
}

.button_text:hover {
    cursor: pointer;
}

.button_icon_container {
  position: static;
  width: 16px;
  height: 16px;
}

.button_icon {
  position: static;
  width: 100%;
  height: 100%;
}
