.center {
  position: absolute;
  left: 50%;
  top: 104px;
  bottom: 128px;
  transform: translate(-50%, 0);
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
}

.scroll_container_content {
  overflow: scroll;
  overflow-x: hidden;
  padding-left: 12px;
  padding-right: 12px;
}

.step_title {
  font-family: "Kepler Std";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;

  padding-top: 16px;
  padding-left: 12px;
  padding-bottom: 16px;

  color: #0c2c40;
}

.hidden {
  visibility: hidden;
}

.button_next_container {
  width: 361px;
  height: 40px;

  display: flex;
  justify-content: space-between;

  margin-top: 16px;
}

.nav_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 75px;
  height: 40px;
}

.nav_button_disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 75px;
  height: 40px;
}

.nav_button_disabled:hover {
  cursor: progress;
}

.nav_button:hover {
    opacity: 0.7;
    cursor: pointer;
    transition: 0.2s;
}

.nav_button:not(hover) {
    transition: 0.2s;
}

.content_button {
  padding-left: 8px;
  padding-right: 8px;
  width: fit-content;
}

.button_next {
  background: #ff7700;
  border-radius: 48px;
}

.button_next_disabled {
  background: #df6e0b54;
  border-radius: 48px;
}

.button_next_text_disabled {
  color: #ffffff;
}

.button_next_text_disabled:hover {
  color: #ffffff;
  cursor: progress;
}

.button_back {
  border: 2px solid #0c2c40;
  box-sizing: border-box;
  border-radius: 48px;
}

.nav_button_text {
  font-family: "Adelle Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;

  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.button_next_text {
  color: #ffffff;
}

.button_back_text {
  color: #0c2c40;
}

.button_link {
  color: #ffffff;
  cursor: default;
  text-decoration: none;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: fit-content;
  color: white;
  font-size: 12px;
  background-color: #192733;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
}

#top {
  top: -40px;
  left: -50%;
}

#bottom {
  top: 60px;
  left: 20%;
}

#left {
  top: 0px;
  right: 120%;
}

#right {
  top: -8px;
  left: 120%;
}

.hover-text {
  position: relative;
  display: inline-block;
  font-family: Arial;
  text-align: center;
}