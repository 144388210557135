.title_create_object_financing {
  margin-left: 80px;

  font-family: 'Kepler Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;

  flex: 100%;

  color: #0c2c40;

  margin-bottom: 16px;
}

@media (max-height: 801px) {
  .title_create_object_financing {
    margin-left: 160px;
  }
}

.title_create_object_financing {
  margin-top: 29px;
}

.form_financing_data_object_center {
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 112px;
  align-items: center;
}

.seperator {
  height: 0px;
  border: 2px solid #ebe5e5;

  margin-top: 29px;
  margin-bottom: 29px;
}

.form_bottom_row {
  width: 100%;
}

.form_financing_hidden {
  display: none !important;
}
