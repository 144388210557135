.radio_button_container {
  display: flex;
  flex-direction: row;
  margin: 8px 0px;
}

.radio_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;

  opacity: 0.5;

  border: 1px solid #3f81ba;
  box-sizing: border-box;
  border-radius: 36px;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}

    .radio_button:hover {
        cursor:pointer;
        opacity: 0.7;
    }

.radio_button_text {
  font-family: "Adelle Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;

  display: flex;
  align-items: flex-end;

  color: #0c2c40;
  cursor: pointer;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}

.selected_text {
  font-family: "Adelle Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;

  display: flex;
  align-items: flex-end;

  color: #ffffff;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}

.selected_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;

  background: #0c2c40;

  border: 1px solid #3f81ba;
  box-sizing: border-box;
  border-radius: 36px;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}
