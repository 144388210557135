.object_container_small {
  position: relative;
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
}

.object_container_small_sub_option {
  position: relative;
  width: 100%;
  height: 30px;
}

.object_data_container_small {
  position: absolute;
  display: flex;
  width: 100%;
  height: 40px;

  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;

  margin-top: 0px;

  flex: 100%;
}

.object_data_container_small_flex {
  display: flex;
  width: 100%;
  height: fit-content;

  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;

  margin-top: 0px;

  flex: 100%;
}

.object_data_container_small_sub_option {
  position: relative;
  display: flex;
  width: 90%;
  height: 30px;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0;
  box-sizing: border-box;
  border-radius: 4px;
  float: right;

  padding-right: 8px;
  margin-right: 8px;
  margin-top: -16px;

  flex: 100%;
}

.object_row_container_small {
  margin: auto;
  display: flex;
  flex: 100%;
  width: 100%;
  align-items: center;
  gap: 2%;
  justify-content: flex-start;
}

.object_column_container_small {
  margin: auto;
  padding: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}

.object_title_small {
  font-family: "Adelle Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;

  flex-grow: 1;

  color: #0c2c40;
  padding: 0px;
  margin-left: 8px;
}

.object_type_small {
  font-family: "Adelle Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: #0c2c40;
  padding: 0px;
}

.object_type_small_flex {
  font-family: "Adelle Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: #0c2c40;
  padding: 0px;
}

.object_type_error {
  color: red;
}

.object_background_small {
  float: right;
  padding-right: 8px;
  height: 100%;
}

.flex-1 {
  flex: 1;
}

.flex-8 {
  flex: 8;
}