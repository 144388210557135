.object_container {
  position: relative;
  min-width: 365px;
  width: 50%;
  height: 100px;

  margin-top: 16px;
}

.object_data_container {
  position: absolute;
  display: flex;
  min-width: 365px;
  width: 50%;
  height: 100px;

  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;

  justify-content: space-between;
}

.object_title {
  position: static;
  width: 100%;
  height: 18px;
  padding-left: 8px;
  padding-top: 8px;

  font-family: "Adelle Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #0c2c40;
}

.object_extra_title {
  padding-top: 12px;
}

.object_extra_subtitle {
  position: static;
  width: 100%;
  height: 14px;
  padding-left: 8px;
  padding-top: 8px;
  white-space: pre-line;

  font-family: "Adelle Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #0c2c40;
}

.object_type {
  position: static;
  width: 100%;
  height: 14px;
  padding-left: 8px;
  padding-top: 26px;
  white-space: pre-line;

  font-family: "Adelle Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #0c2c40;
}

.object_background {
  padding-right: 8px;
}

.object_actions_container {
  position: absolute;
  display: flex;
  min-width: 368px;
  height: 80px;

  justify-content: flex-end;
}

.object_actions_button_container {
  display: flex;
  flex-direction: column;
  width: 37px;
  height: 95px;
  padding-right: 25px;

  justify-content: space-between;
}

.object_edit {
  width: 25px;
  height: 25px;

  margin-top: 12px;
}

.object_delete {
  width: 25px;
  height: 25px;

  margin-bottom: 12px;
}

.object_edit:hover {
    cursor: pointer;
    transition: 0.2s;
    filter: saturate(-10);
    transform: scale(1.25);
}

.object_delete:hover {
    cursor: pointer;
    transition: 0.2s;
    filter: invert() saturate(10);
    transform: scale(1.25);
}

.object_edit:not(hover) {
    transition: 0.2s;
}

.object_delete:not(hover) {
    transition: 0.2s;
}