.history_page {
  width: 100%;
  height: 70%;
  padding-left: 80px;
  padding-right: 80px;
}

.data_grid {
  height: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
}

.padding-top {
  padding-top: 32px;
}

.edit_icon {
  width: 20px;
  height: 20px;
  color: 'red';
}

.edit_icon:hover {
  transition: 0.2s;
  filter: invert() saturate(10);
  transform: scale(1.15);
  cursor: pointer;
}

.edit_icon:not(:hover) {
  transition: 0.2s;
  transform: scale(1);
}
