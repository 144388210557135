.global_values_save_button {
  flex-direction: column-reverse;
}

.interest_row {
  max-width: 100%;
  display: flex;
  flex-direction: row;
}

.interest_container {
  max-width: 14.28%;
}

.interest_input {
  max-width: 100%;
}

.interest_rate_table {
  margin-bottom: 8px;
}

.upfront_fee_table {
  min-height: 578px;
}

.interest_rate_table_changed {
  background-color: rgb(255, 244, 229) !important;
}

.input_field:disabled {
  background-color: lightgray;
}

.no_data {
  color: orangered;
}

.table_no_data {
  margin-top: 16px;
  margin-bottom: 16px;
}

.background_logo {
  z-index: -10;
}
