.input_title {
  position: static;
  height: 12px;
  left: 0px;
  top: 0px;

  font-family: "Adelle Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: flex-end;

  color: #0c2c40;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.input_container {
  height: 76px;
  flex-grow: 10;
}

.input_container_small {
  height: 76px;
  flex-grow: 5;
}

.input_container_tiny {
  height: 76px;
  flex-grow: 0.1;
}

.input_required {
  font-family: "Adelle Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}

.input_required:after {
  padding-left: 2px;
  content:"*";
  color: red;
}

.input_error {
  color: red;
  font-family: "Adelle Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}


.input_error_container {
  border-color: red !important;
}

.input_field {
  position: static;
  width: 100%;
  height: 40px;
  left: 0px;
  top: 20px;

  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 6px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;

  padding-left: 16px;
}

.flex_input_field {
    flex: 1;
    max-width: 14.28%;
  
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    border-radius: 6px;
}

.input_field:hover {
    border-bottom-color: darkorange;
}

.valuta_disabled {
  background-color: lightgray;
}

.error {
  color: red;
}

.transparent {
  opacity: 0.5;
}