.dropdown_field {
  position: static;
  width: 100%;
  height: 40px;
  left: 0px;

  text-indent: 0px;

  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 6px;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 8px 0px;

  font-size: 14px;
}

.option_text p {
  font-family: "Adelle Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 16px;

  color: #0c2c40;
}