.footer {
  height: 110px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.05);
}

.footer_container {
  display: flex;
  margin-top: 16px;
  align-items: stretch;
}

.wizard_step_container {
  height: 100%;
  width: 100%;
}

.footer_step {
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.wizard_navigation_text {
  font-family: "Adelle Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;

  color: #0c2c40;
}

.wizard_navigation_image {
  position: relative;
}

.wizard_navigation_step_number {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);

  font-family: "Adelle Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;

  color: #ffffff;

  cursor: default;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.wizard_progress_bar {
  position: absolute;
  width: 100%;
  height: 15px;
  bottom: 0;
  left: 0;

  background: #3f81ba;
  border-radius: 0px 0px 4px 4px;
}

.wizard_progress_bar_progress_step_1 {
  position: absolute;
  width: 16%;
  height: 15px;
  bottom: 0;
  left: 0;

  background: #0c2c40;
  border-radius: 0px 0px 0px 4px;
}

.wizard_progress_bar_progress_step_2 {
  position: absolute;
  width: 48.5%;
  height: 15px;
  bottom: 0;
  left: 0;

  background: #0c2c40;
  border-radius: 0px 0px 0px 4px;
}

.wizard_progress_bar_progress_step_3 {
  position: absolute;
  width: 82.5%;
  height: 15px;
  bottom: 0;
  left: 0;

  background: #0c2c40;
  border-radius: 0px 0px 0px 4px;
}
