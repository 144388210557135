.header_container {
  flex: 1;
  
  display: flex;
  min-height: 88px;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.05);
}

.header_logo_container {
  flex: 4;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.header_logo {
  flex: 1;
}

.logout_container {
  position: absolute;
  right: 5%;
}

.logout_button {
  flex: 1;
  width: 150px;
}

.logout_border {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border: 1px solid rgba(234, 66, 64, 0.5);
    box-sizing: border-box;
    border-radius: 36px;
    cursor: pointer;
}

    .logout_border:hover {
        transition: 0.2s;
        border-color: darkorange;
    }

    .logout_border:not(hover) {
        transition: 0.2s;
    }

.logout_text {
  font-family: "Adelle Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;

  display: flex;
  align-items: flex-end;

  color: #ea4240;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;

  cursor: default;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.logout_border:hover p {
    transition: 0.2s;
    color: darkorange;
    cursor: pointer;
}

.logout_border:not(hover) p {
    transition: 0.2s;
}



.box_container {
  flex: 1;
  width: 100%;
  min-height: 88px;
  max-height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.05);
}