.title_create_object {
  font-family: 'Kepler Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;

  margin-top: 4rem;

  color: #0c2c40;
}

h3 {
  font-family: 'Kepler Std';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;

  color: #0c2c40;
}

.form {
  margin-top: 16px;
  padding-bottom: 16px;

  display: grid;
  flex-wrap: wrap;
  grid-gap: 4%;
  grid-template-columns: repeat(2, 1fr);
}

.form_grid_row_financial_loans {
  grid-column: 1;
}

@media (max-height: 801px) {
  .form {
    flex-direction: column;
  }
}

.form_button_container {
  flex: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 16px;
  padding-right: 65px;
}

.form_padding_bottom {
  padding-bottom: 50px;
}

.form_center {
  padding-left: 80px;
  padding-right: 80px;

  flex: 1;
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
}

.form_left {
  float: left;
}

.form_right {
  float: right;
}

.form_row_start_1 {
  grid-row-start: 1;
}

.form_row_start_2 {
  grid-row-start: 2;
}

.form_row_start_3 {
  grid-row-start: 3;
}

.form_row_start_4 {
  grid-row-start: 4;
}

.form_col_start_1 {
  grid-column-start: 1;
}

.form_col_start_2 {
  grid-column-start: 2;
}

.button_cancel_text {
  font-family: 'Adelle Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  color: #0c2c40;
  cursor: pointer;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

.button_cancel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;

  border: 1px solid #0c2c40;
  box-sizing: border-box;
  border-radius: 48px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 16px;
}

.button_cancel:hover {
  opacity: 0.7;
  transition: 0.2s;
  cursor: pointer;
}

.button_save {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;

  background: #ff7700;
  border-radius: 48px;
  border: none;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 16px;

  font-family: 'Adelle Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;

  cursor: pointer;
  color: #ffffff;
}

.relation_person_extra_input_column {
  padding-top: 75px;
}

.form_row {
  display: flex;
  flex-direction: row;
  flex: 100%;
  width: 100%;
  column-gap: 16px;

  margin: 8px 0px;
}

.form_row_end {
  display: flex;
  flex-direction: row;
  flex: 100%;
  width: 100%;
  column-gap: 16px;

  margin: 8px 0px;
  justify-content: flex-end;
}

.form_bottom_row {
  width: 100%;
}

.hidden_build_depot {
  display: none !important;
}

.director_row {
  display: flex;
  flex-direction: row;

  flex: 100%;
  width: 100%;
  padding: 10px;
  padding-left: 0px;

  font-size: 12px;
}

.director_row_item {
  display: flex;
  flex-direction: row;
  flex: 100%;
  width: 100%;
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background_logo {
  position: fixed;
  left: 0px;
  bottom: -150px;
  transform: scale(1.2, 1.2) rotate(-30deg);
  overflow: hidden;
}

.text {
  font-family: 'Adelle Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
  color: #0c2c40;
}

.data_grid {
  flex-grow: 1;
}

.data_grid_container {
  height: 100%;
}

.form_container {
  flex: 1;
}

.form_fill {
  padding-left: 80px;
  padding-right: 80px;
  margin-top: 16px;
  padding-bottom: 16px;

  display: flex;
}

.padding-left {
  padding-left: 80px;
}
