@import url('../../assets/fonts/fonts.css');

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.33);
  z-index: 10;
}

.modal-main {
  font-family: 'Adelle Sans';
  position: fixed;
  background: white;
  width: 100%;
  max-width: 600px;
  min-width: 350px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.modal-title {
  font-family: 'Kepler Std';
  margin-left: 8px;
  margin-bottom: -8px;
  color: #0c2c40;
}

.modal-content {
  width: 100%;
  height: auto;
  display: flex;
}

.modal-content input,
select {
  font-family: 'Adelle Sans';
  font-size: 16px;
  color: #0c2c40;
  background-color: white;
  border-color: lightgray;
  border-width: 1px;
  border-radius: 4px;
  height: 32px;
  border-style: solid;
  padding-left: 8px;
}

.modal-content input:hover,
select:hover {
  border-bottom: 1px solid #ff7700;
}

.modal-content-column {
  display: flex;
  flex-direction: column;
  flex: 0.5;
  margin: 8px;
}

.modal-content-column label {
  margin-top: 8px;
}

.modal-content-row {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.model-content-row-option {
  flex: 0.5;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modal-button-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.modal-button-positive {
  color: white;
  background-color: #ff7700;
  border-radius: 48px;
  border: none;
  height: 40px;
  padding-top: 16px;
  padding-bottom: 28px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 8px;
}

.modal-button-loading {
  color: white;
  background-color: #d96500;
  border-radius: 48px;
  border: none;
  height: 44px;
  display: flex;
  justify-content: center;
  min-width: 80px;
  margin: 8px;
  padding-top: 5px;
}

.modal-button-positive:hover {
  background-color: #d96500;
  transition: 0.2s;
}

.modal-button-positive:not(hover) {
  background-color: #ff7700;
  transition: 0.2s;
}

.modal-button-negative {
  color: #0c2c40;
  background: none;
  border-radius: 48px;
  border: 1px solid #0c2c40;
  height: 40px;
  padding-top: 16px;
  padding-bottom: 28px;
  padding-left: 16px;
  padding-right: 16px;
  transition: 0.2s;
  margin: 8px;
}

.modal-button-negative:hover {
  background-color: #f0f0f0;
  transition: 0.2s;
}

.modal-button-negative:not(hover) {
  opacity: 1;
  transition: 0.2s;
}
