.hide_link {
  cursor: default;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  text-decoration: none;
}

.intro_container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.intro_page {
  cursor: pointer;
  transform-origin: left;
}

.intro {
  transform-origin: left;
  transition: 1s;
  transform: scale(4);
  opacity: 0;
}
