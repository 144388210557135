.title {
  position: absolute;
  width: 347px;
  height: 116px;
  left: 127px;
  top: 50%;
  margin: 0px;

  transform: translate(0, -50%);

  font-family: 'Kepler Std';
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;

  color: #0c2c40;
}

.link {
  width: 250px;
  height: 19px;
  left: 94.5px;
  font-family: 'Adelle Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
  color: #0c2c40;
  text-decoration: none;
}

.link:hover {
  cursor: pointer;
}

.link p {
  color: #0c2c40;
  text-decoration: none;
}

.link a {
  color: #0c2c40;
  text-decoration: none;
}

.link_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.link_container:hover > .arrow_right {
  transition: 0.2s;
  transform: translate(8px, 0);
  -webkit-transform: translate(8px, 0);
  -moz-transform: translate(8px, 0);
  -o-transform: translate(8px, 0);
  -ms-transform: translate(8px, 0);
}

.link_container:not(hover) > .arrow_right {
  transition: 0.2s;
}

.arrow_right {
  left: 20%;
}

.amount_background {
  width: 255px;
  height: 30px;

  opacity: 0.35;

  background-color: #ffffff;
  border-radius: 16px;
}

.amount_text {
  text-align: center;

  font-family: 'Adelle Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;

  color: #ffffff;
}

p {
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: row;
  flex-basis: auto;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 30px;

  width: 70%;
  height: 100%;
}

.container_vertical {
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 30px;
}

.amount_container {
  width: 255px;
  height: 53px;
}

.hidden {
  display: none;
}

.user_dashboard_center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.admin_amount_container {
  background: #0c2c40;
  border-radius: 4px;

  padding-top: 37px;
  padding-bottom: 37px;
}

.admin_amount_text_container {
  display: flex;
  flex-direction: row;

  height: 50%;

  justify-content: center;
  align-items: center;
}

.admin_amount_text {
  font-family: 'Adelle Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;

  height: 42px;

  flex: 1;

  padding-left: 24px;

  color: #ffffff;

  text-align: center;
}
.admin_amount_seperator {
  height: 55px;
}
.admin_amount_value {
  font-family: 'Adelle Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 36px;

  height: 42px;

  flex: 1;

  padding-right: 24px;

  color: #ffffff;

  text-align: center;
}
